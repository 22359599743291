


.css-d7l1ni-option{
    background-color:rgba(0, 0, 0, 0.05)!important;
    color:var(--primary)!important;
  }
  .css-d7l1ni-option:hover{
    background-color:rgba(0, 0, 0, 0.05)!important;
    color:var(--primary)!important;
  }
  .css-1nmdiq5-menu{
    z-index: 99999!important;
    border-radius: 0.75rem!important;
  }
  
  .css-13cymwt-control:focus{
    outline: none!important;
    border-color: yellow!important;
    box-shadow: none!important;
  }
  .css-t3ipsp-control,.css-13cymwt-control{
    outline: none!important;
    border-color: yellow!important;
    box-shadow: none!important;
    border: 1px solid #E6E6E6!important;
    appearance: none!important;
    border-radius: 0.75rem!important;
  }
  
  .css-1p3m7a8-multiValue
  {
    background-color: var(--primary)!important;
    border-radius: 10px!important;
    padding:0!important
  }
  .css-wsp0cs-MultiValueGeneric{
  color:white!important
  }
  .css-12a83d4-MultiValueRemove{
    border-top-right-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
  
  color:white
  }
  
  
  
  .appswitch input {
    display: none;
  }
  
  #menu > li.mm-active> a:hover {
  
    opacity: 1;
  
  }
  
  #menu > li > a:hover {
  
    opacity: .8;
  
  }
  
  
  
  
  
  
  [direction='rtl']input {
  direction: rtl;
  }
  
  [direction='ltr']input {
    direction: ltr;
    }
  
    #loader{
      z-index: 999999;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, .9);
      
    }
    .loader-wrap{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
    }
  
  
    .commonImage {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      object-fit: cover;
  
    }

.commonCard{
    
        display: flex;
         flex-direction: column;
        align-items: center;
        padding: 20px;
        border: 1px dashed black;

        border-radius: 20px;  
        margin-bottom: 20px;
    }

    .commonHeader{
      font-size: 20px;
      margin-top: 20px;
      margin-inline-end: 10px
  }


    .blackDivider{ height: 1px; width: 100%;background-color:black; margin-block: 10px }
    @media (min-width:1000px) {
            .commonCard{
               width: 48%;
            }
    }
    @media (max-width:999px) {
        .commonCard{
            width: 100%;
        }
}

  .form-control:before {
display: none!important;
}
.form-control:after {
  display: none!important;
  }



  @media (min-width: 576px) {
    .modal2{
      max-width: 70%!important;
   }
 
  }


  .myModal{
    width: max-content!important;
  }